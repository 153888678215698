<template>
    <div class="header">
        <nav>
            <ul>
                <li v-if="role === '0'">
                    <a href="/support_data/export" target="_blank">CSVダウンロード</a>
                </li>
                <li>
                    <button type="button" @click="handleLogOut()">ログアウト</button>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
    "use strict";

    import common from "@/mixins/common";

    export default {
        "mixins": [
            common,
        ],
        data() {
            return {
                "role": localStorage.getItem("role"),
            };
        },
        "methods": {
            handleLogOut() {
                if (this.role === "0") {
                    this.$router.push({ "name": "offices_logout" });
                } else if (this.role === "1") {
                    this.$router.push({ "name": "teams_logout" });
                }
            },
        },
    };
</script>
