<template>
    <div class="menu">
        <div class="menu-logo"><img src="@/assets/images/logo.png" v-bind="logoAttrs"></div>
        <nav>
            <ul>
                <template v-for="(menu, key) in menu">
                    <li v-if="role === '0' || (key !== 'teams_list' && key !== 'similar_keywords_list')" :key="key">
                        <router-link :to="{ name: menu.link }" :active-class="active">{{ menu.text }}</router-link>
                    </li>
                </template>
            </ul>
        </nav>
    </div>
</template>

<script>
    "use strict";

    import common from "@/mixins/common";

    export default {
        "mixins": [
            common,
        ],
        data() {
            return {
                "active": "is-current",
                "logoAttrs": {
                    "alt": "",
                    "title": "",
                },
                "menu": {
                    "search": {
                        "link": "search",
                        "text": "検索ページ",
                    },
                    "my_page": {
                        "link": "my_page",
                        "text": "マイページ",
                    },
                    "teams_list": {
                        "link": "teams_list",
                        "text": "チーム管理",
                    },
                    "similar_keywords_list": {
                        "link": "similar_keywords_list",
                        "text": "類似キーワード管理",
                    },
                },
                "role": localStorage.getItem("role"),
            };
        },
    };
</script>
