<template>
    <div id="app" class="wrapper">
        <Menu />
        <div class="content">
            <Header />
            <router-view />
        </div>
    </div>
</template>

<script>
    "use strict";
    
    import Header from "@/components/TheHeader.vue";
    import Menu from "@/components/TheMenu.vue";

    export default {
        "components": {
            Header,
            Menu,
        },
    };
</script>
